import { Link } from 'gatsby';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PageLayout from 'src/components/PageLayout/PageLayout';
import { useTheme } from 'src/hooks';
import RadioTelescope from 'src/svgs/radio_telescope.svg';

function NotFoundPage() {
  const t = useTheme();

  return (
    <PageLayout withoutHeader withoutFooter>
      <Container fluid className="min-vh-100 bg-darker py-8">
        <Row>
          <Col xs={12} lg={{ span: 4, offset: 4 }} className="d-flex flex-column align-items-center pt-6">
            <RadioTelescope style={{ maxHeight: '350px' }} />
            <h1 className="h2 pt-4 text-center text-white">{t.pages.notFound.title}</h1>
            <p className="text-white-75 py-2 text-center">{t.pages.notFound.subTitle}</p>
            <Link to="/" className="btn btn-gradient-secondary">
              {t.pages.notFound.linkToHomeLabel}
            </Link>
          </Col>
        </Row>
      </Container>
    </PageLayout>
  );
}

export default NotFoundPage;
